import classes from './Banner.module.css';
import Section from '../../layout/Section';
import Image from 'react-bootstrap/Image';
import bannerImage from '../../assets/LilNounClassroom.png';
import { Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <Section fullWidth={false} className={classes.bannerSection}>
      <div className={classes.wrapper}>
        <h1 style={{ textAlign: 'center' }}>
          ONE BLAST NOUN,
          <br />
          EVERY 24 HOURS,
          <br />
          FOREVER.
        </h1>
      </div>
      
    </Section>
  );
};

export default Banner;
