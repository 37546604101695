import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  const playgroundLink = <Link text="Playground" url="/playground" leavesPage={false} />;
  const nounsDao = <Link text="Nouns DAO" url="https://nouns.wtf" leavesPage={true} />;
  const publicDomainLink = (
    <Link
      text="public domain"
      url="https://creativecommons.org/publicdomain/zero/1.0/"
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link text="Compound Governance" url="https://compound.finance/governance" leavesPage={true} />
  );

  const lilBlockPartyLink = <Link text="Lil Block Party" url="https://lilblockparty.wtf" leavesPage={true} />;

  return (
    <Section fullWidth={false}>
      <Col lg={{ span: 10, offset: 1 }} >
        <div className={classes.headerWrapper}>
          <h1>WTF?</h1>
          <p className={classes.aboutText}>
           
            <br />
            <br />
            Blast Nouns are inspired by NounsDAO. With the power of Blast L2, Blast Nouns can create native yield with community treasury and reduce gas fee. We aim to build the best community on Blast L2 and empower the builders, community members through yields and incentives. 100% of the Blast tokens and yield will go to community treasury, shared and decided by every Blast Noun holder.
          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '4rem' }}>
            Learn more below, or start creating Blast nouns off-chain using the {playgroundLink}.
          </p>
        </div>
        <Accordion flush style={{backgroundColor:'#F7F035'}}>
          <Accordion.Item eventKey="0" className={classes.accordionItem} >
            <Accordion.Header className={classes.accordionHeader}>Summary</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Blast Nouns artwork is in the {publicDomainLink}.</li>
                <li>One Blast  Noun is trustlessly auctioned every 24 hours, forever.</li>
                <li>
                100% of Blast Noun auction proceeds are trustlessly sent to the treasury.
                </li>
                <li>Settlement of one auction kicks off the next.</li>
                <li>All Blast Nouns are members of Blast Nouns DAO.</li>
                <li>Blast Nouns DAO uses Nouns DAO’s fork of  {compoundGovLink}.</li>
                <li>One Blast noun is equal to one vote.</li>
                <li>The treasury is controlled exclusively by Nouns via governance.</li>
                <li>Artwork is generative and stored directly on-chain (not IPFS).</li>
                <li>
                No explicit rules exist for attribute scarcity; all Nouns are equally rare.
                </li>
                <li>
                Blast Nounders receive rewards in the form of Blast Nouns (10% of supply for first 5 years).
                </li>
                
                
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Daily Auctions
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText} >
              The Blast Nouns Auction Contract will act as a self-sufficient Blast Noun generation and distribution mechanism, auctioning one Noun every 24 hours, forever. 100% of auction proceeds (ETH) are automatically deposited in the Blast Nouns DAO treasury, where they are governed by Blast Noun owners.

              </p>

              <p className={classes.aboutText}>
              Each time an auction is settled, the settlement transaction will also cause a new Blast Noun to be minted and a new 24 hour auction to begin.
While settlement is most heavily incentivized for the winning bidder, it can be triggered by anyone, allowing the system to trustlessly auction Nouns as long as Ethereum is operational and there are interested bidders.{' '}
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
            Powered by Blast, Built for Blast community
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
              Blast Nouns DAO is developed on Blast L2, which means our community treasury will earn passive yield and keeps growing as time goes by. 100% of the yield and potential Blast tokens will go to Blast Nouns treasury and decided by Blast Noun holders. With the power of community treasury and incentives, we believe Blast Nouns communtiy will become one of the strongest and most creative ones on Blast L2. The journey just begins.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Governance ‘Slow Start’</Accordion.Header>
            <Accordion.Body>
            Blast Nouns DAO utilizes a fork of Compound Governance and is the main governing body of the Blast Nouns ecosystem. The Blast Nouns DAO treasury receives 100% of ETH proceeds from daily Blast Noun auctions. Each Blast Noun is an irrevocable member of Blast Nouns DAO and entitled to one vote in all governance matters. Noun votes are non-transferable (if you sell your Noun the vote goes with it) but delegatable, which means you can assign your vote to someone else as long as you own your Blast Noun.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>Blast noun Traits</Accordion.Header>
            <Accordion.Body>
              <p>
              Blast Nouns are generated randomly based Ethereum block hashes. There are no 'if' statements or other rules governing Blast Noun trait scarcity, which makes all Blast Nouns equally rare. As of this writing, Blast Nouns are made up of:
              </p>
              <ul>
                <li>backgrounds (2) </li>
                <li>bodies (30)</li>
                <li>accessories (140) </li>
                <li>heads (242) </li>
                <li>glasses (23)</li>
              </ul>
              You can experiment with off-chain Blast noun generation at the {playgroundLink}.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              On-Chain Artwork
            </Accordion.Header>
            <Accordion.Body>
              <p>
              Blast Nouns are stored directly on Blast L2 and do not utilize pointers to other networks such as IPFS. This is possible because Blast Noun parts are compressed and stored on-chain using a custom run-length encoding (RLE), which is a form of lossless compression.
              </p>

              <p>
              The compressed parts are efficiently converted into a single base64 encoded SVG image on-chain. To accomplish this, each part is decoded into an intermediate format before being converted into a series of SVG rects using batched, on-chain string concatenation. Once the entire SVG has been generated, it is base64 encoded.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Blast noun Seeder Contract
            </Accordion.Header>
            <Accordion.Body>
              <p>
              The Blast Noun Seeder contract is used to determine Blast Noun traits during the minting process. The seeder contract can be replaced to allow for future trait generation algorithm upgrades. Additionally, it can be locked by the Blast Nouns DAO to prevent any future updates. Currently, Blast Noun traits are determined using pseudo-random number generation:
              </p>
              <code>keccak256(abi.encodePacked(blockhash(block.number - 1), nounId))</code>
              <br />
              <br />
              <p>
              Trait generation is not truly random. Traits can be predicted when minting a Blast Noun on the pending block.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              Blast nounder's Reward
            </Accordion.Header>
            <Accordion.Body>
              <p>
              'Blast Nounders' are the group of builders that initiated Lil Nouns. Here are the Blast Nounders:
              </p>
              <ul>
                <li>
                  <Link text="@blastnounsdao" url="https://twitter.com/blastnounsdao" leavesPage={true} />
                </li>
              </ul>
              <p>
              Because 100% of Blast Noun auction proceeds are sent to Blast Nouns DAO, Blast Nounders have chosen to compensate themselves with Blast Nouns. Every 10th Blast Noun for the first 5 years of the project (Blast Noun ids #0, #10, #20, #30 and so on) will be automatically sent to the Blast Nounder's multisig to be vested and shared among the founding members of the project.
              </p>
              <p>
              Blast Nounder distributions don't interfere with the cadence of daily auctions. Blast Nouns are sent directly to the Blast Nounder's Multisig, and auctions continue on schedule with the next available Blast Noun ID.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
